.homescreen {
  display: -webkit-box;
  /* display: flex; */
  margin-bottom: 10vh;
}

.hs_phone {
  background-image: linear-gradient(180deg, #47575f, #ffffff);
  height: 600px;
  width: 276px;
  border: 2px solid #8d949f;
  border-radius: 40px;
  margin: 20px 50px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
}
.hs_phone .frame-notch {
  background-image: linear-gradient(180deg, #47575f, #ffffff);
  width: 97px;
  height: 20px;
  border: 2px solid #8d949f;
  position: relative;
  left: 82px;
  top: -2px;
  border-top-color: #fff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.hs_phone_outer {
  border-width: 10px;
  border-color: #37474f;
  background-image: linear-gradient(180deg, #47575f, #ffffff);
}
.hs_phone_outer .frame-notch {
  border-width: 10px;
  top: -3px;
  border-color: #37474f;
  background: #37474f;
}

.ctn_button {
  position: absolute;
  top: 480px;
  margin-left: 7px;
}

.ctn_button > button {
  padding: 5px;
  background-color: red;
  outline: none;
  border: 0;
  color: white;
  border-radius: 15px;
}

.hs_upload {
  width: 256px;
  height: 520px;
  /* background-color: #cccccc; */
  border-radius: 16px;
}

.heading {
  float: left;
}

.btn-all {
  float: right;
}

.each_heading > h3 {
  font-size: 24px;
  font-weight: 600;
}

.dropdown_history > span > svg {
  width: 25% !important;
}

.hs_upload > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hs_logo_uploader > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hs_logo_uploader > div {
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;
}

.hs_upload > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 160px;
}

.hs_upload > div > h4 {
  /* vertical-align: middle; */
}

.hs_logo_uploader {
  margin-left: 10px;
  border: 1px dotted #ffffff;
  /* padding: 15px; */
  height: 75px;
  width: 75px;
  position: absolute;
  top: 300px;
}

.hs_logo_uploader > div {
  text-align: center;
}

.add_hs {
  opacity: 0.5;
}

.react-multi-carousel-item {
  width: 100% !important;
}

.delete_button {
  width: 45px;
  height: 45px;
  position: absolute;
  margin-left: 239px;
  font-size: 3vh;
  color: red;
  background-color: #ffccccdd;
  padding: 6px;
  border-radius: 45px;
  cursor: pointer;
  bottom: 105px;
}

.sure_delete {
  display: flex;
  background-color: #444444;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  width: 260px;
  border: 1px solid #c9ffc9;
  bottom: 105px;
}

.delete_yes {
  background-color: red;
  color: white;
  /* width: 69px; */
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
}

.delete_yes > p {
  margin: 0;
}

.delete_no {
  background-color: #999999;
  border-radius: 5px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.delete_no > p {
  margin: 0;
}

.hs_numbering {
  display: flex;
  justify-content: center;
  /* margin-top: 45px; */
  font-size: 60px;
  font-weight: 600;
  color: #999999;
  position: absolute;
  bottom: 0;
  margin-left: 50px;
}

.hs_numbering:hover {
  filter: brightness(85%);
}

.hs_numbering > *:first-child {
  margin-top: 20px;
  color: #cccccc;
}

.hs_numbering > *:last-child {
  margin-top: 20px;
  color: #cccccc;
}

/* .hs_numbering > *:first-child:hover {
  color: #999999;
}

.hs_numbering > *:last-child:hover {
  color: #999999;
} */
