.mytabs {
  position: absolute;
  width: 256px;
  bottom: 110px;
}

.mytabs > .row {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0px -5px 5px -5px;
}

.loc_mytabs {
  margin-top: 0;
  top: -20px;
}

.eachTab {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.eachTab > * {
  display: block;
  text-align: center;
  color: #444444;
}
.eachTab > div:nth-child(1) {
  font-size: 2vh;
}

.eachTab > div > p {
  padding: 0;
  margin: 0 !important;
}

.bottom {
  /* width: 100%;
  height: 0.7vh;
  border-radius: 10px;
  background-color: red;
  margin-bottom: -0.5vh; */
  border-bottom: 2px solid red;
}

.myTabCol {
  padding-left: 0;
  padding-right: 0;
}

.nobottom {
  /* height: 0.7vh;
  margin-bottom: -0.5vh;
  background-color: white; */
  border-bottom: 2px solid white;
}

p {
}
