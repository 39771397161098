.anticon svg {
  display: inline-block;
  width: 20px !important;
}

.heading > h3 {
  border: none;
}

.hs_markdown > h1,
h2,
h3,
h4,
h5,
h6 {
  border: none !important;
}

.all_images > .ant-upload.ant-upload-select-picture-card {
  width: 200px;
  height: 200px;
}

.all_images > .ant-upload-list > .ant-upload-list-picture-card-container {
  width: 200px !important;
  height: 200px !important;
}
.all_images
  > .ant-upload-list
  > .ant-upload-list-picture-card-container
  > span
  > .ant-upload-list-item {
  width: 20vw !important;
  height: 20vw !important;
}

.absolutes {
  position: absolute;
}

.hs_markdown {
  margin-top: 10px;
  cursor: pointer;
  position: relative;
  top: -200px;
  z-index: 10;
}

.hs_markdown {
  margin: 7px;
}

.hs_markdown > div > h2 {
  /* color: white; */
  /* text-shadow: 0px 0px 4px black; */
  /* margin-left: 7px; */
}

.hs_markdown > div > div > * {
  padding: 0;
  /* margin-left: 7px; */
  margin: 0;
  text-shadow: 0px 0px 4px #cccccc;
}

.img_gallery {
  display: inline-block;
}

.img_gallery > img {
  margin: 15px;
}

.ant-tabs-tab > span > svg {
  width: 20px !important;
  font-size: 15px;
  margin-bottom: -3px;
}

.settings_main {
  min-height: 100vh;
}

.settings_main > h3 {
  border: none;
}

.setting_color {
  display: flex;
}

.settings_primary_colorpicker {
  margin-left: 20px;
}

.markdown_settings > p {
  display: flex;
}

.actual_component {
  /* display: flex;
  overflow-x: auto;
  overflow-y: hidden; */
}

.actual_component > div {
  margin-right: 5vw;
  margin-left: 5vw;
}

.settings_primary_colorpicker > div > div > div:first-child {
  /* width: 50px !important;
  height: 50px !important; */
}

.settings_primary {
  display: flex;
  width: 650px;
}

.setting_color {
  display: block;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.markdown_settings {
  position: absolute;
  margin-left: 80%;
  margin-top: 3%;
  z-index: 2;
}

.settings_palette_container {
  width: 50px;
  height: 50px;
  margin: 0 15px 30px 15px;
}

.settings_palette_container > p {
  position: absolute;
  left: 85px;
  top: 128px;
}

.settings_color_palette {
  display: flex;
  flex-wrap: wrap;
}

.ant-upload-picture-card-wrapper {
  width: 200px;
  margin: 1%;
}

.shouldDelete {
  background-color: #444444;
  float: left;
  display: flex;
  height: 32px;
  width: 130px;
  justify-content: space-around;
  margin-left: 10px;
  border-radius: 2px;
}

.shouldDelete > div {
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 5px;
  color: #fff;
}

.shouldDelete > .shouldDeleteNo {
  background-color: #999999;
}

.shouldDelete > .shouldDeleteTrue {
  background-color: #ff0000;
}

.settings_counts {
  position: absolute;
}

.logouplaoder_image {
  display: flex;
  flex-wrap: wrap;
}

.logouploader_image > span {
  width: 20vw;

  margin: 1%;
}

.logouplaoder_image > .logouploader_eachimage {
  margin: 1%;
  position: relative;
}

.logouploader_eachimage > img {
  padding-bottom: 2%;
  width: 20vw;
}

.logouploader_edit {
  position: absolute;
  bottom: 10px;
  right: 0px;
  color: #0980ef;
  background-color: #eeeeeec7;
  padding: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
