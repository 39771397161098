.loc_home_upload {
  width: 256px;
  height: 340px;
  background-color: #cccccc;
  border-radius: 16px 16px 0 0;
}

/* .loc_home_top{
  
} */

.loc_home_main {
  height: 90%;
  width: 256px;
  overflow-y: auto;
  overflow-x: hidden;
}

.loc_home_upload > .upload_img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 160px; */
  position: relative;
  top: 200px;
}

.loc_home_phone {
  margin-top: 120px;
}

.loc_home_phone > p {
  text-shadow: 0px 0px 2px #444444;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

.loc_home_phone > button {
  background-color: rgb(193, 29, 46);
  border: 1px solid rgb(193, 29, 46);
  color: #fff;
  border: 1px solid red;
  border-radius: 10px;
  height: 25px;
  margin-right: 20px;
  font-weight: 500;
}

.loc_home_upload > .loc_home_logo {
  position: relative;
  /* top: -310px; */
  right: -10px;
}

.loc_home_upload > .loc_home_logo > img {
  height: 70px;
}

.loc_home_upload > .loc_home_logo > h3 {
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  padding: 0;
  /* white-space: nowrap; */
  text-shadow: 0px 0px 2px #444444;
}

.loc_home_upload > .loc_home_logo > p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  padding: 0;
  text-shadow: 0px 0px 2px #444444;
}

/* gallery loc */
.loc_gal_upload {
  width: 256px;
  height: 520px;
  /* background-color: #cccccc; */
  border-radius: 30px;
}

.loc_gal_upload > .upload_img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 160px; */
  position: relative;
  top: 200px;
}

.loc_gal_upload > img {
  height: 560px;
  object-fit: cover;
  border-radius: 0 0 30px 30px;
}

.loc_gal_upload > .loc_gal_logo {
  position: relative;
  /* top: 270px; */
  left: 10px;
}

.loc_gal_upload > .loc_gal_logo > img {
  height: 70px;
}

.loc_gal_text {
  position: relative;
}

.loc_gal_text > h2 {
  margin: 10px;
  font-size: 2rem;
  color: #fff;
  text-shadow: 0 0 6px #000;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
}

.mytabsloc {
  position: relative;
  width: 257px;
  top: -40px;
}

.loc_home_menu {
  display: block;
  width: 256px;
}

.loc_home_each {
  justify-content: space-between;
  display: flex;
  padding: 2vh;
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.loc_home_each > svg {
  font-size: 4vh;
  float: right;
  width: 20% !important;
}

.loc_gal_suredel {
  bottom: 110px;
}

/* Barbers start */
.loc_bar_main {
  background-color: rgb(193, 29, 46);
  color: white;
  height: auto;
  width: 100%;
  margin-top: 45%;
  border-radius: 20px 20px 0 0;
}

.loc_bar_outer {
  background: white !important;
}

.loc_bar_profile {
  width: 140px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: -100px;
  height: 170px;
  background-color: #cccccc;
}

.loc_bar_bio {
  text-align: center;
  position: relative;
  top: -90px;
}

.loc_bar_bio > h4 {
  color: white;
  font-size: 5vh;
  font-weight: 600;
  margin-bottom: 0;
}

.loc_hour_main {
  background-color: rgb(193, 29, 46);
  padding: 2vh;
  height: 90%;
  overflow-y: auto;
}

.loc_hour_main > * {
  margin-bottom: 2vh;
  height: 12vh;
  background-color: #fff;
  border-radius: 15px;
}

.loc_hour_container {
  display: flex;
  justify-content: space-between;
  padding-left: 2vh;
}

.loc_hour_container > h2 {
  text-transform: capitalize;
  margin-bottom: 0;
  align-self: center;
  font-size: 14px;
  font-weight: 600;
}

.loc_hour_container > p {
  font-style: italic;
  align-self: center;
  padding-right: 2vh;
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 600;
}

.loc_bar_bio > p {
  font-size: 3vh;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
}

.loc_miss_main {
  height: 90%;
  background-color: rgb(193, 29, 46);
  overflow-y: auto;
  overflow-x: hidden;
}

.loc_miss_profile {
  height: 350px;
  background-color: #cccccc;
}

.loc_miss_profile > * {
  height: 100%;
}

.loc_miss_profile > .upload_img > * {
  align-self: center;
  display: flex;
  justify-content: center;
  color: 'white';
}

.loc_miss_bio > h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-top: 14px;
  color: white;
}

.loc_miss_bio > div > p {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  margin-top: 14px;
  color: white;
}

.loc_rev_card {
  height: auto;
  background-color: white;
  border-radius: 15px;
  position: relative;
  top: 15px;
  margin: 10px;
}

.loc_rev_add {
  height: 100px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  position: relative;
  top: 15px;
  margin: 10px;
}
.loc_rev_add > * {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.loc_rev_top {
  padding: 5px;
}

.loc_rev_pro {
  background-color: #cccccc;
  height: 60px;
  border-radius: 50%;
  width: 60px;
}

.loc_rev_pro > * {
  font-size: 24px;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-radius: 50%;
}

.loc_rev_top {
  display: flex;
}

.loc_rev_name > h3 {
  font-size: 16px;
  font-weight: 600;
}

.loc_rev_name > div {
  display: flex !important;
  width: 100px;
  padding-left: 5px;
}

.loc_rev_bio {
  color: #000;
  padding: 10px;
}

.menu_settings {
  cursor: pointer;
  float: right;
  height: 0;
}

.menu_settings > svg {
  float: right;
  position: relative;
  left: 40px;
  top: -49px;
  background-color: #e6f6ff;
  /* box-shadow: 0px 0px 10px 1px #cccccc; */
  /* padding: 10px; */
  border-radius: 5px;
  /* margin-bottom: 0 !important; */
  height: 40px;
  margin: 1px;
  padding: 11px;
  color: #3499ff;
}

.menu_set_barber > svg {
  top: 0px;
}

.loc_rev_btn {
  width: 30px;
  height: 30px;
  position: absolute;
  margin-left: 200px;
  font-size: 3vh;
  color: red;
  background-color: #ffccccdd;
  padding: 0px;
  border-radius: 45px;
  cursor: pointer;
  bottom: 3px;
}

.loc_rev_btn > svg {
  height: 15px;
}

.loc_rev_del {
  display: flex;
  background-color: #444444;
  padding: 3px;
  border-radius: 5px;
  position: absolute;
  width: 200px;
  border: 1px solid #c9ffc9;
  bottom: 0px;
}

.set_each_inputs {
  display: grid;
}

.set_flex_inputs {
  display: flex;
  justify-content: space-between;
}

.set_flex_inputs > span {
  width: 85%;
}

.ant-input-group-addon {
  width: 100px !important;
}

.loc_bar_social > table > tbody > tr > td:first-child {
  text-align: center;
  font-size: 5vh;
  width: 10%;
}

.loc_bar_social > table > tbody > tr > td {
  width: 25%;
}

.loc_ser_container {
  border: 1px solid #44444426;
  background-color: white;
  margin: 5px;
  border-radius: 10px;
  width: 200px !important;
}

.loc_ser_heading {
  background-color: #dddddd;
  padding: 5px;
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0 0;
}

.loc_ser_services > div {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #44444426;
}

.loc_ser_services > div > p:first-child {
  width: 70%;
}
.loc_ser_services > div:last-child {
  border-radius: 0 0 10px 10px;
}
